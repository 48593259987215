<template>
  <div class="amui-efficiency-class-icon" :class="classes">
    <div class="amui-efficiency-class-icon__label">
      {{ value }}
    </div>
    <svg-corner class="amui-efficiency-class-icon__icon" />
  </div>
</template>

<script>
import svgCorner from './assets/corner.svg'

export const AMUI_EFFICIENCY_CLASSES = [
  'A+++',
  'A++',
  'A+',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G'
]

export default {
  name: 'AmuiEfficiencyClassIcon',

  components: {
    svgCorner
  },

  props: {
    value: {
      type: String,
      required: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    classes() {
      return [
        'amui-efficiency-class-icon--class-' +
          this.value
            .replace('+++', '-ppp')
            .replace('++', '-pp')
            .replace('+', '-p')
            .toLowerCase(),
        {
          'amui-efficiency-class-icon--small': this.small
        }
      ]
    }
  }
}
</script>
