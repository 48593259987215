import { formatPrice } from './../../../utils/formatter'
import {
  getTitle as getVehicleTitle,
  getId as getVehicleId,
  getPreviewImage as getVehiclePreviewImage,
  getDefaultImage as getVehicleDefaultImage,
  getTypeLetter as getVehicleTypeLetter,
  usedCarSealSources as _usedCarSealSources,
  getDeliveryDateInWeeks
} from '@/../utils/vehicle'

import { getPricing } from './../../../utils/vehicle'
import vehiclePriceHelperMixin from '@/app/mixins/vehicle-price-helper.mixin'
import vehicleFeatureHelperMixin from '@/app/mixins/vehicle-features-helper.mixin'
import vehicleMixin from '@/app/mixins/vehicle.mixin'
import { mapState, mapGetters } from 'vuex'
import { getMonth, getYear, parseISO } from 'date-fns'

export default {
  mixins: [vehiclePriceHelperMixin, vehicleFeatureHelperMixin, vehicleMixin],
  computed: {
    ...mapState('core', ['rawVehicleMarketCompanyData']),
    ...mapGetters('core', ['getIntegrationByName']),
    ...mapGetters('favorites', ['isFavoriteByVehicleId'])
  },
  methods: {
    getMappedVehicleCardData(rawVehicle) {
      const vehicleId = getVehicleId(rawVehicle)
      const vehicleTypeLetter = getVehicleTypeLetter(rawVehicle)
      const pricing = getPricing(rawVehicle)
      const usedCarSealSources = _usedCarSealSources(rawVehicle)
      const isSold = typeof pricing !== 'object'

      let rateTopLabel = null
      let rate = null
      let rateRaw = null
      let rateBottomLabel = null

      // an financing offer (leasing or financing)
      if (pricing && pricing.financingOffer.data !== null) {
        rateTopLabel = this.$t('price.labels.monthly-from')

        rate = this.$t('price.indications.simple', {
          price: formatPrice(pricing.financingOffer.computed.rate.grossRate)
        })

        rateRaw = formatPrice(pricing.financingOffer.computed.rate.grossRate)

        rateBottomLabel = this.getRateBottomLabel(
          formatPrice(pricing.financingOffer.computed.rate.netRate),
          pricing.financingOffer.data.data.vatRate
        )
      }

      // deliveryDate
      let availabilityInWeeks = null
      let availabilityLabel = null

      if (
        rawVehicle.deliveryDate !== undefined &&
        rawVehicle.amUsageType === 'NEW'
      ) {
        availabilityInWeeks = getDeliveryDateInWeeks(rawVehicle.deliveryDate)

        if (availabilityInWeeks === 0) {
          availabilityLabel = this.$t('vte.deliveryDate.now')
        } else if (availabilityInWeeks <= 12) {
          availabilityLabel = this.$tc(
            'vte.deliveryDate.weeks',
            availabilityInWeeks,
            { count: availabilityInWeeks }
          )
        } else {
          const month = getMonth(parseISO(rawVehicle.deliveryDate)) + 1
          const year = getYear(parseISO(rawVehicle.deliveryDate))
          availabilityLabel = this.$t('vte.deliveryDate.date', {
            month: this.$t('date.month.short.' + month),
            year
          })
        }
      }

      // purchase price
      let priceTopLabel = pricing && this.$t('price.labels.purchase-price')
      let price =
        pricing &&
        this.$t('price.indications.simple', {
          price: formatPrice(pricing.offer.data.consumerPriceGross)
        })
      let priceBottomLabel =
        pricing &&
        this.getPriceSubline(
          formatPrice(pricing.offer.data.consumerPriceNet),
          pricing.offer.data.vatRate
        )

      const priceTaxInfo =
        pricing && this.getPriceTaxInfo(pricing.offer.data.vatRate)

      // crossed out price
      let crossedOutPrice = null
      let crossedOutPriceSuffix = null
      let crossedOutPriceReferenceNumber = null

      if (pricing && pricing.offer.computed.crossedOutPrice !== null) {
        crossedOutPrice = this.$t('price.indications.simple', {
          price: formatPrice(pricing.offer.computed.crossedOutPrice)
        })

        crossedOutPriceSuffix = this.getCrossedOutPriceSuffix(vehicleTypeLetter)

        const priceLegalTexts = this.getCrossedOutPriceLegalTexts(
          vehicleTypeLetter
        )

        if (priceLegalTexts !== undefined) {
          crossedOutPriceReferenceNumber = priceLegalTexts.referenceNumber
        }
      }

      // usage type
      let usageTypeObject = null

      if (rawVehicle.amUsageType) {
        usageTypeObject = {
          label: this.$t(
            'vehicleClass.' +
              rawVehicle.vehicleClass +
              '.amUsageType' +
              rawVehicle.amUsageType
          ),
          value: rawVehicle.amUsageType
        }
      }

      // highlights
      let highlights = []

      // 1. usage type
      if (usageTypeObject !== null) {
        highlights.push({
          label: usageTypeObject.label,
          priority: usageTypeObject.value === 'NEW' ? 'primary' : 'secondary'
        })
      }

      // 2. discount price
      if (pricing && pricing.offer.computed.discountInPercent !== null) {
        highlights.push({
          label: '-' + pricing.offer.computed.discountInPercent + '%',
          priority: 'discount'
        })
      }

      // 3. highlights by api
      let customerHighlights = []

      if (Array.isArray(rawVehicle.highlights)) {
        rawVehicle.highlights.forEach(label => {
          highlights.push({
            label,
            priority: 'default'
          })
          customerHighlights.push({
            label
          })
        })
      }

      // vehicle title
      const title = getVehicleTitle(rawVehicle)

      // vehicle preview image
      let media = []

      const vehiclePreviewImage = getVehiclePreviewImage(rawVehicle)
      if (vehiclePreviewImage !== null) {
        media.push(vehiclePreviewImage)
      } else {
        media.push({
          type: 'cpo',
          normal: getVehicleDefaultImage(
            this.rawVehicleMarketCompanyData.vehicleDefaultImages,
            rawVehicle
          ),
          alt: this.$t(
            'vehicleClass.' + rawVehicle.vehicleClass + '.defaultImageAltText'
          )
        })
      }

      // vehicle url
      const vehicleUrl = this.$router.resolve({
        name: 'detail',
        params: {
          id: vehicleId
        }
      }).href

      // is favorite
      const isFavorite = this.isFavoriteByVehicleId(vehicleId)

      const autounclePriceRatingIntegration = this.getIntegrationByName(
        'autouncle-price-rating',
        rawVehicle.mobileSellerId
      )

      // interior/exterior
      let interiorObj = null
      let exteriorObj = null

      const {
        manufacturerColorName,
        exteriorColor,
        interiorColor,
        interiorType
      } = rawVehicle

      if (exteriorColor !== undefined) {
        exteriorObj = {
          title: this.$t('vehicle.exteriorColor'),
          label: manufacturerColorName,
          value: String(exteriorColor).toLowerCase()
        }
      }

      if (interiorColor !== undefined) {
        interiorObj = {
          title: this.$t('vehicle.interiorColor'),
          label:
            this.$t('vehicle.interiorType' + interiorType) +
            ' ' +
            this.$t(
              'vehicle.interiorColor' +
                (interiorColor || 'OTHER_INTERIOR_COLOR')
            ),
          value: String(interiorColor || 'white').toLowerCase()
        }
      }

      const getEnergyEfficiencyClass = rawVehicleData => {
        return rawVehicleData && rawVehicleData.amNedcValues !== undefined
          ? rawVehicleData.energyEfficiencyClass
          : null
      }

      return Object.assign(
        {},
        {
          id: vehicleId,
          _id: rawVehicle._id, // Datenbank ID, use this as key in vue lists
          mobileAdId: rawVehicle.mobileAdId,
          isFavorite,
          title,
          highlights,
          media,
          rateTopLabel,
          rate,
          rateRaw,
          rateBottomLabel,
          priceTopLabel: priceTopLabel || '',
          price: price || '',
          priceBottomLabel: priceBottomLabel || '',
          priceTaxInfo: priceTaxInfo || '',
          autounclePriceRatingIntegration,
          url: vehicleUrl,
          crossedOutPrice,
          crossedOutPriceSuffix,
          crossedOutPriceReferenceNumber,
          efficiencyClass: getEnergyEfficiencyClass(rawVehicle),
          efficiencyText: this.getVehicleConsumptionLegalText(rawVehicle),
          efficiencyTextReferenceNumber: '1',
          features: this.getHighlightedFeatures(rawVehicle),
          usedCarSealSources,
          offer: pricing && pricing.financingOffer.data, // forwarded to amui-financing-popover
          sold: isSold,
          // new for vehicle-tile-extended
          make: rawVehicle.make,
          amModelYear: rawVehicle.amModelYear || null,
          model: rawVehicle.model,
          usageType: usageTypeObject,
          availabilityInWeeks,
          availabilityLabel,
          customerHighlights,
          interior: interiorObj,
          exterior: exteriorObj,
          vehicleClass: rawVehicle.vehicleClass
        }
      )
    }
  }
}
