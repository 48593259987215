<template>
  <div class="amui-lazy-image">
    <img
      :fetchpriority="fetchPriority"
      draggable="false"
      :src="computedSource"
      :alt="alt"
    />
  </div>
</template>

<script>
export default {
  name: 'AmuiLazyImage',

  props: {
    source: {
      type: String,
      required: false,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: null
    },
    fetchPriority: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      intersectionObserverInstance: null,
      resizeObserverInstance: null,
      isIntersecting: false,
      imageWrapWidth: null
    }
  },

  watch: {
    imageWrapWidth(width) {
      this.$emit('change', width)
    }
  },

  computed: {
    computedSource() {
      return (this.isIntersecting || this.fetchPriority) && this.source
        ? this.source
        : 'https://cdn.automedia.de/widgets/vehicle-market/assets/various/placeholder.svg'
    }
  },

  mounted() {
    const intersectionCallback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isIntersecting = true
        }
      })
    }

    this.intersectionObserverInstance = new IntersectionObserver(
      intersectionCallback
    )

    this.intersectionObserverInstance.observe(this.$el)

    this.initResizeObserver()
    this.observeImageSize()
  },

  beforeDestroy() {
    this.unobserveImageSize()
    this.intersectionObserverInstance &&
      this.intersectionObserverInstance.disconnect()
  },

  methods: {
    initResizeObserver() {
      function debounce(f, delay) {
        let timer = 0
        return function(...args) {
          clearTimeout(timer)
          timer = setTimeout(() => f.apply(this, args), delay)
        }
      }

      this.resizeObserverInstance = new ResizeObserver(
        debounce(entries => {
          for (const entry of entries) {
            if (entry.contentBoxSize) {
              this.imageWrapWidth = Math.round(
                entry.contentBoxSize[0].inlineSize
              )
            }
          }
        }),
        250
      )
    },
    observeImageSize() {
      this.resizeObserverInstance &&
        this.resizeObserverInstance.observe(this.$el)
    },
    unobserveImageSize() {
      this.resizeObserverInstance && this.resizeObserverInstance.disconnect()
    }
  }
}
</script>
