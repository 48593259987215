export default {
  methods: {
    getVehicleConsumptionLegalText(
      rawVehicleData,
      i18nKey = 'vehicle.efficiencyText'
    ) {
      let {
        consumptionUnit,
        wltpValues,
        amNedcValues,
        hybridPlugin,
        fuel
      } = rawVehicleData

      let text

      const isPluginHybrid = hybridPlugin === true
      const isElectric = fuel === 'ELECTRICITY'
      const getValue = v => (v === undefined ? '-' : v)

      if (amNedcValues || wltpValues) {
        if (isElectric) {
          text = this.$t(
            i18nKey + 'Electric' + (wltpValues === undefined ? 'NEFZ' : 'WLTP'),
            {
              consumptionPowerWLTP: getValue(
                wltpValues?.consumptionPowerCombined
              ),
              consumptionPowerNEFZ: getValue(
                amNedcValues?.consumptionPowerCombined
              ),
              co2EmissionWLTP: getValue(wltpValues?.co2EmissionCombined),
              co2EmissionNEFZ: getValue(amNedcValues?.co2EmissionCombined),
              electricRangeWLTP:
                wltpValues?.electricRange === undefined
                  ? ''
                  : ' ' +
                    this.$t('vehicle.efficiencyTextElectricElectricRangeWLTP', {
                      range: wltpValues?.electricRange
                    })
            }
          )
        } else if (isPluginHybrid) {
          text = this.$t(
            i18nKey +
              'PluginHybrid' +
              (wltpValues === undefined ? 'NEFZ' : 'WLTP'),
            {
              consumptionFuelWLTP: getValue(
                wltpValues?.consumptionFuelCombinedWeighted
              ),
              consumptionPowerWLTP: getValue(
                wltpValues?.consumptionPowerCombinedWeighted
              ),
              consumptionFuelNEFZ: getValue(
                amNedcValues?.consumptionFuelCombinedWeighted
              ),
              consumptionPowerNEFZ: getValue(
                amNedcValues?.consumptionPowerCombinedWeighted
              ),
              co2EmissionWLTP: getValue(
                wltpValues?.co2EmissionCombinedWeighted
              ),
              co2EmissionNEFZ: getValue(
                amNedcValues?.co2EmissionCombinedWeighted
              ),
              electricRangeWLTP:
                wltpValues?.electricRange === undefined
                  ? ''
                  : ' ' +
                    this.$t(
                      'vehicle.efficiencyTextPluginHybridElectricRangeWLTP',
                      {
                        range: wltpValues?.electricRange
                      }
                    )
            }
          )
        } else {
          // all nonelectric vehicles
          text = this.$t(
            i18nKey + (wltpValues === undefined ? 'NEFZ' : 'WLTP'),
            {
              consumptionFuelWLTP: getValue(
                wltpValues?.consumptionFuelCombined
              ),
              consumptionFuelNEFZ: getValue(
                amNedcValues?.consumptionFuelCombined
              ),
              consumptionUnit: this.$t(
                'vehicle.consumptionUnit' + consumptionUnit
              ),
              co2EmissionWLTP: getValue(wltpValues?.co2EmissionCombined),
              co2EmissionNEFZ: getValue(amNedcValues?.co2EmissionCombined)
            }
          )
        }
      }

      return text
    }
  }
}
